<template>
  <v-app>

    <v-navigation-drawer v-model="drawer" app v-if="!isLogin">
      <v-list>
        <v-list-item v-for="item in items" :key="item.title" @click="openRoute(item)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!isLogin"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="require('./assets/svg-studio-logo.svg')" transition="scale-transition" width="170" @click="$router.push('/')" />
        USA
      </div>

      <v-spacer></v-spacer>

    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import EventBus from '@/event-bus';
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: false,
    isLogin: false,
    items: [
      { icon: 'mdi-home', title: 'Übersicht', path: '/' },
      { icon: 'mdi-widgets', title: 'Produkte', path: '/poster' },
      { icon: 'mdi-image', title: 'Produktbilder', path: '/pictures' },
      { icon: 'mdi-image', title: 'Designs', path: '/designs' },
      { icon: 'mdi-image', title: 'Bestellungen', path: '/orders' },
      { icon: 'mdi-image', title: 'Bestellung bearbeiten', path: '/order/configurator' },
      { icon: 'mdi-star', title: 'Konfigurator Testen', path: '/configurator' },
      { icon: 'mdi-format-font', title: 'Schriftarten', path: '/fontmanager' },
      { icon: 'mdi-logout', title: 'Ausloggen', path: '/auth/logout' },
      { icon: 'mdi-image', title: 'Fehlerhafte Designs', path: '/failqueue' },
    ],
  }),

  created() {
    EventBus.$on('login', () => {
      this.isLogin = false;
    });
  },

  watch: {
    $route() {
      if (this.$route.name === 'login') {
        this.isLogin = true;
      }
    }
  },

  updated() {
    if (this.$route.name === 'login') {
      this.isLogin = true;
    }
  },

  methods: {
    openRoute(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
