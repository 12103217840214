//import { parse } from "svg-parser"

import convert from "xml-js"

class SVGParser {

    constructor(svgFile = null) {
        this.textElements = [];
        this.imageElements = [];
        this.genericElements = [];
        this.idCounter = 0;

        if (svgFile) {
            this.data = svgFile;
            this.process();
        }
    }
    addImageElement(attributes = {}) {
        const id = `Image-${this.getId()}`;
        
        const imageElement = {
            type: "element",
            name: "image",
            attributes: {
                id: id,
                x: "100",
                y: "100",
                width: "200",
                height: "200",
                style: "fill: #ffffff;",
                preserveAspectRatio: "none",
                'xlink:href': '',
                ...attributes
            }
        };

        // Create a clean copy without Vue reactivity
        const cleanElement = JSON.parse(JSON.stringify(imageElement));
        this.imageElements.push(cleanElement);
        
        return cleanElement;
    }

    setSvgFile(svgFile) {
        this.data = svgFile;
        this.process();
    }

    process() {

        this.idCounter = 0;
        this.textElements = [];
        this.imageElements = [];
        this.genericElements = [];
        //First convert xml 2 json

        let xmlJSON = convert.xml2js(this.data);

        //now  cycle  through every child until its deepest depth and give IDs everywhere
        this.processChilds(xmlJSON)

        this.data = convert.js2xml(xmlJSON)

        console.log("PROCCESSING DONE")


    }

    processChilds(child) {
        if (child.attributes) {
            if (child.type == "element") {
                if (child.name == "text") {
                    if (!child.attributes) child.attributes = {};
                    if (!child.attributes.id) child.attributes.id = "Text-" + this.getId();
                    this.textElements.push(child);
                } else if (child.name == "image") {
                    if (!child.attributes) child.attributes = {};
                    if (!child.attributes.id) child.attributes.id = "Image-" + this.getId();
                    // Just push the element as is, no need to reassign xlink:href
                    this.imageElements.push(child);
                } else {
                    if (!child.attributes.id) child.attributes.id = "Element-" + this.getId();
                    this.genericElements.push(child);
                }
            }
        }
        if (child.elements) {
            for (let c of child.elements) {
                this.processChilds(c);
            }
        }
    }


    findElement(child, id) {
        if (child.attributes && child.attributes.id == id) {
            return child;
        }
        if (child.elements) {
            for (let c of child.elements) {
                let found = this.findElement(c, id);
                if (found) return found;
            }
        }
        return null;
    }


    updateSVG(svgData) {
        this.data = svgData;
        const xmlJSON = convert.xml2js(this.data);

        // Update image elements
        for (let imageElement of this.imageElements) {
            const id = imageElement.attributes.id;
            const newImageElement = this.findElement(xmlJSON, id);

            if (newImageElement) {
                // Save old values we want to preserve
                const oldHref = imageElement.attributes['xlink:href'];
                const oldPreserveAspectRatio = imageElement.attributes.preserveAspectRatio;

                // Update all attributes
                imageElement.attributes = {...newImageElement.attributes};

                // Restore preserved values if they were lost
                if (oldHref && !imageElement.attributes['xlink:href']) {
                    imageElement.attributes['xlink:href'] = oldHref;
                }
                if (oldPreserveAspectRatio && !imageElement.attributes.preserveAspectRatio) {
                    imageElement.attributes.preserveAspectRatio = oldPreserveAspectRatio;
                }
            }
        }

        // Update other elements as before
        for (let textElement of this.textElements) {
            const id = textElement.attributes.id;
            const newTextElement = this.findElement(xmlJSON, id);
            if (newTextElement) {
                textElement.attributes = newTextElement.attributes;
            }
        }

        for (let genericElement of this.genericElements) {
            const id = genericElement.attributes.id;
            const newGenericElement = this.findElement(xmlJSON, id);
            if (newGenericElement) {
                genericElement.attributes = newGenericElement.attributes;
            }
        }
    }

    renameNodeId(oldId, newId) {
        //this function should rename a node id and update all references to it
        //first find the node

        //now update all references to it
        for (let textElement of this.textElements) {
            if (textElement.attributes.id == oldId) {
                textElement.attributes.id = newId;
            }
        }
        for (let imageElement of this.imageElements) {
            if (imageElement.attributes.id == oldId) {
                imageElement.attributes.id = newId;
            }
        }

        for (let genericElement of this.genericElements) {
            if (genericElement.attributes.id == oldId) {
                genericElement.attributes.id = newId;
            }
        }

    }

    getId() {
        this.idCounter++;
        return this.idCounter
    }

    getTextElements() {
        return this.textElements;
    }
    getImageElements() {
        return this.imageElements;
    }

    getGenericElements() {
        return this.genericElements;
    }

    getElementIds() {
        let ids = [];
        for (let textElement of this.textElements) {
            ids.push(textElement.attributes.id);
        }
        for (let imageElement of this.imageElements) {
            ids.push(imageElement.attributes.id);
        }
        for (let genericElement of this.genericElements) {
            ids.push(genericElement.attributes.id);
        }

        return ids;
    }

    getJson() {
        return this.json;
    }

    getSVGFile() {
        return this.data;
    }



}

export default SVGParser;