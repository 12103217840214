import axios from 'axios'

const apiKey = "ayUEHgX1TV9h5X6l2ugz2MxP4NiClHVojB2rPpOL002bFd3MSfw3apX5lPp9";

export default () => {
  const instance = axios.create({
    baseURL: `https://svg.us.accelery.host/api/`,
    headers: {
      "X-Api-Key": apiKey
    },
    timeout: 1000 * 190,
    withCredentials: true,
  })

  // Fehlerbehandlungs-Interceptor
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        // Der Server hat mit einem Fehlercode geantwortet
        console.error('API-Fehler:', error.response.status, error.response.data)
      } else if (error.request) {
        // Die Anfrage wurde gesendet, aber keine Antwort erhalten
        console.error('Keine Antwort vom Server:', error.request)
      } else {
        // Fehler beim Erstellen der Anfrage
        console.error('Anfragefehler:', error.message)
      }
      
      return Promise.reject(error)
    }
  )

  return instance
}