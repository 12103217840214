<template>
  <div class="fail-queue">
    <h1>Fehlerhafte Designs</h1>
    
    <div class="filters">
      <v-text-field
        v-model="search"
        label="Nach Design-ID suchen"
        append-icon="mdi-magnify"
        outlined
        dense
      />
    </div>

    <v-dialog v-model="editDialog" max-width="1000px">
      <v-card>
        <v-card-title class="headline primary white--text">
          Design bearbeiten
          <v-spacer></v-spacer>
          <v-chip dark>SKU: {{ editedItem?.originalDesign?.options?.sku }}</v-chip>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <v-tabs v-model="activeTab" background-color="transparent" grow>
            <v-tab>
              <v-icon left>mdi-tune</v-icon>
              Optionen
            </v-tab>
            <v-tab>
              <v-icon left>mdi-xml</v-icon>
              SVG
            </v-tab>
          </v-tabs>
          
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <!-- Options Editor -->
              <v-form ref="optionsForm">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        type="info"
                        outlined
                        dense
                      >
                        Bearbeiten Sie die Design-Optionen und klicken Sie auf "Speichern", um die Änderungen zu übernehmen.
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col 
                      v-for="(value, key) in groupedOptions" 
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <v-card outlined class="pa-4 mb-4">
                        <v-card-title class="subtitle-1 px-0">
                          {{ getGroupTitle(key) }}
                        </v-card-title>
                        <v-card-text class="px-0">
                          <v-row dense>
                            <v-col cols="12" v-for="field in value" :key="field.key">
                              <v-text-field
                                v-model="editedOptions[field.key]"
                                :label="getFieldLabel(field.key)"
                                :hint="getFieldHint(field.key)"
                                persistent-hint
                                outlined
                                dense
                                :disabled="isFieldDisabled(field.key)"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
            
            <v-tab-item>
              <!-- SVG Handler -->
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      type="warning"
                      outlined
                      dense
                    >
                      Achtung: Das Hochladen einer neuen SVG-Datei überschreibt die bestehende Vorlage.
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      accept=".svg"
                      label="SVG-Datei hochladen"
                      @change="handleSVGUpload"
                      outlined
                      prepend-icon="mdi-file-upload"
                      :rules="[v => !v || v.size < 2000000 || 'SVG Datei sollte kleiner als 2MB sein']"
                    />
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      color="primary"
                      @click="downloadSVG"
                      :loading="downloading"
                      large
                    >
                      <v-icon left>mdi-download</v-icon>
                      SVG herunterladen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-btn
            color="grey darken-1"
            text
            @click="closeEditDialog"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="saveChanges"
            :loading="saving"
            :disabled="!hasChanges"
          >
            <v-icon left>mdi-content-save</v-icon>
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="failQueueItems"
      :search="search"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:no-data>
        <div class="empty-state">
          <v-icon size="64" color="grey lighten-1">mdi-check-circle</v-icon>
          <h3 class="mt-4">Keine fehlerhaften Designs</h3>
          <p class="grey--text">Aktuell befinden sich keine Designs in der Fehlerwarteschlange</p>
        </div>
      </template>

      <template v-slot:item.error="{ item }">
        <div class="error-message">{{ item.error }}</div>
      </template>

      <template v-slot:item.attempts="{ item }">
        <v-chip
          :color="getAttemptsColor(item.attempts)"
          dark
        >
          {{ item.attempts }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="info"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          Bearbeiten
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="retryProcessing(item)"
          :loading="processingItems[item._id]"
        >
          Erneut versuchen
        </v-btn>
        <v-btn
          small
          color="error"
          class="ml-2"
          @click="removeFromQueue(item)"
          :loading="removingItems[item._id]"
        >
          Entfernen
        </v-btn>
      </template>

    </v-data-table>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import FailQueueService from '@/services/failQueueService';

export default {
  name: 'FailQueue',
  
  data() {
    return {
      search: '',
      loading: false,
      failQueueItems: [],
      processingItems: {},
      removingItems: {},
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      },
      editDialog: false,
      activeTab: 0,
      editedItem: null,
      editedOptions: {},
      downloading: false,
      saving: false,
      headers: [
        { text: 'Design ID', value: 'designId' },
        { text: 'Fehler', value: 'error' },
        { text: 'Versuche', value: 'attempts' },
        { text: 'Datum', value: 'created' },
        { text: 'SKU', value: 'originalDesign.options.sku' },
        { text: 'Aktionen', value: 'actions', sortable: false }
      ],
      originalOptions: {},
      optionGroups: {
        basic: ['designId', 'sku', 'orderId'],
        text: ['line1', 'line2', 'line3', 'line4', 'line5'],
        meta: ['created', 'managedId', 'tag', 'table'],
        system: ['pdf', 'poster', 'jpg', 'uploadDuration']
      }
    }
  },

  computed: {
    hasChanges() {
      return JSON.stringify(this.editedOptions) !== JSON.stringify(this.originalOptions);
    },

    groupedOptions() {
      const grouped = {};
      
      // Gruppiere die Optionen nach den definierten Gruppen
      for (const [groupName, fields] of Object.entries(this.optionGroups)) {
        grouped[groupName] = fields.map(key => ({
          key,
          value: this.editedOptions[key]
        })).filter(field => field.value !== undefined);
      }
      
      // Sammle übrige Felder in "other"
      const usedKeys = Object.values(this.optionGroups).flat();
      const otherFields = Object.keys(this.editedOptions)
        .filter(key => !usedKeys.includes(key))
        .map(key => ({ key, value: this.editedOptions[key] }));
      
      if (otherFields.length > 0) {
        grouped.other = otherFields;
      }
      
      return grouped;
    }
  },

  created() {
    this.loadFailQueueItems()
  },

  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text
      this.snackbar.color = color
      this.snackbar.show = true
    },

    async loadFailQueueItems() {
      this.loading = true
      try {
        const response = await FailQueueService.getFailQueueItems()
        this.failQueueItems = response.data
      } catch (error) {
        this.showSnackbar('Fehler beim Laden der fehlerhaften Designs', 'error')
      }
      this.loading = false
    },

    getAttemptsColor(attempts) {
      if (attempts >= 3) return 'red'
      if (attempts >= 2) return 'orange'
      return 'green'
    },

    async retryProcessing(item) {
      this.$set(this.processingItems, item._id, true)
      try {
        await FailQueueService.retryProcessing(item._id)
        this.showSnackbar('Design wird erneut verarbeitet')
        await this.loadFailQueueItems()
      } catch (error) {
        this.showSnackbar(error.message || 'Fehler bei erneuter Verarbeitung', 'error')
      } finally {
        this.$set(this.processingItems, item._id, false)
      }
    },

    async removeFromQueue(item) {
      this.$set(this.removingItems, item._id, true)
      try {
        await FailQueueService.removeFromQueue(item._id)
        this.showSnackbar('Design aus der Warteschlange entfernt')
        await this.loadFailQueueItems()
      } catch (error) {
        this.showSnackbar('Fehler beim Entfernen aus der Warteschlange', 'error')
      } finally {
        this.$set(this.removingItems, item._id, false)
      }
    },

    openEditDialog(item) {
      this.editedItem = item;
      this.editedOptions = JSON.parse(JSON.stringify(item.originalDesign.options));
      this.originalOptions = JSON.parse(JSON.stringify(item.originalDesign.options));
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
      this.editedItem = null;
      this.editedOptions = {};
    },

    async handleSVGUpload(file) {
      if (!file) return;
      
      try {
        await FailQueueService.uploadSVG(this.editedItem.originalDesign.options.sku, file);
        this.showSnackbar('SVG erfolgreich hochgeladen');
      } catch (error) {
        this.showSnackbar('Fehler beim Hochladen der SVG', 'error');
      }
    },

    async downloadSVG() {
      this.downloading = true;
      try {
        const response = await FailQueueService.downloadSVG(this.editedItem.originalDesign.options.sku);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.editedItem.originalDesign.options.sku}.svg`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        this.showSnackbar('Fehler beim Herunterladen der SVG', 'error');
      }
      this.downloading = false;
    },

    async saveChanges() {
      this.saving = true;
      try {
        await FailQueueService.updateDesignOptions(
          this.editedItem.designId,
          this.editedOptions
        );
        this.showSnackbar('Änderungen gespeichert');
        this.closeEditDialog();
        await this.loadFailQueueItems();
      } catch (error) {
        this.showSnackbar('Fehler beim Speichern der Änderungen', 'error');
      }
      this.saving = false;
    },

    getGroupTitle(group) {
      const titles = {
        basic: 'Basis-Informationen',
        text: 'Text-Felder',
        meta: 'Metadaten',
        system: 'System-Informationen',
        other: 'Weitere Optionen'
      };
      return titles[group] || group;
    },

    getFieldLabel(key) {
      const labels = {
        designId: 'Design ID',
        sku: 'SKU',
        orderId: 'Bestell-ID',
        line1: 'Zeile 1',
        line2: 'Zeile 2',
        line3: 'Zeile 3',
        line4: 'Zeile 4',
        line5: 'Zeile 5',
        created: 'Erstellt am',
        managedId: 'Managed ID',
        tag: 'Tag',
        table: 'Tabelle'
      };
      return labels[key] || key;
    },

    getFieldHint(key) {
      const hints = {
        designId: 'Eindeutige Kennung des Designs',
        sku: 'Produkt-Artikelnummer',
        orderId: 'ID der zugehörigen Bestellung',
        line1: 'Erste Textzeile des Designs',
        // ... weitere Hints
      };
      return hints[key] || '';
    },

    isFieldDisabled(key) {
      // Bestimmte Felder sollten nicht editierbar sein
      const disabledFields = ['designId', 'created', 'pdf', 'poster', 'jpg', 'uploadDuration'];
      return disabledFields.includes(key);
    }
  }
}
</script>

<style scoped>
.fail-queue {
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
}

.error-message {
  max-width: 400px;
  white-space: normal;
  word-break: break-word;
}

.empty-state {
  text-align: center;
  padding: 48px;
}

.v-card-title.headline {
  font-size: 1.5rem !important;
}

.v-tab {
  text-transform: none;
  font-weight: 500;
}
</style>
