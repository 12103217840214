<template>
    <v-container fluid>
        <!-- Header Section -->
        <v-row>
            <v-col cols="12">
                <v-card elevation="2" class="mb-6">
                    <v-card-title class="headline">
                        Bestellung bearbeiten
                        <v-spacer></v-spacer>
                        <v-text-field v-model="orderId" label="Bestellnummer" hide-details class="max-width-300" append-icon="mdi-magnify" @click:append="fetchOrderDetails" @keyup.enter="fetchOrderDetails"></v-text-field>
                        <v-btn class="ml-4" color="primary" @click="fetchOrderDetails" :loading="loading">
                            Bestellung laden
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <!-- Error Alert -->
        <v-alert v-if="error" type="error" dismissible class="mb-4">
            {{ error }}
        </v-alert>

        <template v-if="orderDetails">
            <!-- Order Information Section -->
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mb-6">
                        <v-card-title class="subtitle-1">
                            Bestelldetails
                            <v-chip class="ml-4" :color="getStateColor(orderDetails.state)" small>
                                {{ orderDetails.state }}
                            </v-chip>
                        </v-card-title>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="6">
                                    <div class="caption grey--text">Bestellnummer</div>
                                    <div>{{ orderDetails.orderId }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text">Shop</div>
                                    <div>{{ orderDetails.shop }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text">Erstelldatum</div>
                                    <div>{{ formatDate(orderDetails.createDate) }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text">Kunde</div>
                                    <div>{{ orderDetails.customer.firstName }} {{ orderDetails.customer.lastName }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card class="mb-6">
                        <v-card-title class="subtitle-1">Versandinformationen</v-card-title>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="6">
                                    <div class="caption grey--text">Versanddatum</div>
                                    <div>{{ formatDate(orderDetails.shipping.shippmentDate) || 'Nicht festgelegt' }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text">Letztes Versanddatum</div>
                                    <div>{{ formatDate(orderDetails.shipping.lastShippmentDate) || 'Nicht festgelegt' }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Import Card -->
            <v-row v-if="orderDetails.products.filter(x => x.attributes && x.attributes.designId).length == 0">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="warning">
                            <v-icon left>mdi-alert</v-icon>
                            Bestellung muss importiert werden
                        </v-card-title>
                        <v-card-text>
                            <p>Bitte stellen Sie sicher, dass die SKU bereits mit einem Design verknüpft wurde.</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" @click="importOrder" :loading="loading">
                                <v-icon left>mdi-import</v-icon>
                                Bestellung importieren
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Products Section -->
            <template v-for="product in orderDetails.products.filter(x => x.attributes && x.attributes.designId)">
                <v-card :key="product.attributes.designId" class="mb-6">
                    <v-card-title class="subtitle-1">
                        Produkt: {{ product.name }}
                        <v-chip class="ml-4" small>SKU: {{ product.sku }}</v-chip>
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <!-- Preview Image -->
                            <v-col cols="12" md="6" v-if="product.design">
                                <v-card flat class="pa-2">
                                    <div class="preview-wrapper preview-wrapper-large">
                                        <v-img :ref="product.design.options.designId" :src="product.design.options.poster" contain position="center" max-height="500">
                                            <template v-slot:placeholder>
                                                <v-row align="center" justify="center" class="fill-height">
                                                    <v-progress-circular indeterminate></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </div>
                                </v-card>
                            </v-col>

                            <!-- Design Options -->
                            <v-col cols="12" md="6" v-if="product.design">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-text-field label="Design ID" v-model="product.design.designId" readonly dense outlined></v-text-field>
                                            </v-col>

                                            <template v-if="product.posterTemplate">
                                                <v-col cols="12" v-for="option in product.posterTemplate.options" :key="option.field">
                                                    <template v-if="showAllFields || product.design.options[option.field]">
                                                        <v-text-field v-if="option.type === 'text'" :label="option.label" v-model="product.design.options[option.field]" :placeholder="option.placeholder" dense outlined counter></v-text-field>

                                                        <v-card v-else-if="option.type === 'image'" flat class="pa-2 mb-2">
                                                            <div class="d-flex flex-column">
                                                                <div class="text-subtitle-2 mb-2">{{ option.label }}</div>

                                                                <!-- Bildvorschau -->
                                                                <div v-if="product.design.options[option.field]" class="image-preview-container mb-2">
                                                                    <div class="preview-wrapper" :style="{
                                                                        height: '300px',
                                                                        width: '100%',
                                                                        margin: '0 auto',
                                                                        position: 'relative'
                                                                    }">
                                                                        <canvas :id="getCanvasId(product.design.designId, option.field)" class="image-canvas" @click="openImagePreview(product.design.options[option.field], product, option.field, option.aspectRatio)"></canvas>
                                                                        <img :id="getImageId(product.design.designId, option.field)" :src="product.design.options[option.field]" style="display: none;" @load="initCanvas(product.design.designId, option.field)">
                                                                    </div>
                                                                    <div class="image-dimensions caption grey--text text-center mt-1">
                                                                        {{ getImageDimensions(option) }}
                                                                    </div>
                                                                </div>

                                                                <!-- Upload Controls -->
                                                                <div class="d-flex align-center">
                                                                    <v-file-input v-model="imageFiles[option.field]" @change="handleImageUpload($event, option.field, product)" accept="image/*" dense outlined hide-details placeholder="Bild auswählen" prepend-icon="mdi-camera" class="flex-grow-1"></v-file-input>

                                                                    <v-btn v-if="product.design.options[option.field]" icon small color="error" class="ml-2" @click="removeImage(option.field, product)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </template>
                                                </v-col>
                                            </template>

                                            <v-col cols="12" class="text-center">
                                                <v-btn v-if="!showAllFields && hasHiddenFields" color="primary" text @click="showAllFields = true">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    Weitere Felder anzeigen
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn color="success" @click="saveChanges(product.design.options)" :loading="loading">
                                            <v-icon left>mdi-content-save</v-icon>
                                            Speichern & Neu generieren
                                        </v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn-group>
                                            <v-btn v-if="product.design.options.pdf" color="primary" text :href="product.design.options.pdf">
                                                <v-icon left>mdi-file-pdf</v-icon>
                                                PDF
                                            </v-btn>

                                            <v-btn v-if="product.design.options.poster" color="primary" text :href="product.design.options.poster">
                                                <v-icon left>mdi-file-image</v-icon>
                                                PNG
                                            </v-btn>

                                            <v-btn v-if="product.design.options.jpg" color="primary" text :href="product.design.options.jpg">
                                                <v-icon left>mdi-file-jpg</v-icon>
                                                JPG
                                            </v-btn>
                                        </v-btn-group>

                                        <v-btn color="primary" class="ml-2" @click="designPreviewUrl(product.attributes.designId)">
                                            <v-icon left>mdi-refresh</v-icon>
                                            Neu generieren
                                        </v-btn>

                                        <!-- PDF Upload Button -->
                                        <v-btn color="primary" class="ml-2" @click="openPdfUploadDialog(product)">
                                            <v-icon left>mdi-file-upload</v-icon>
                                            PDF hochladen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
        </template>

        <!-- PDF Upload Dialog -->
        <v-dialog v-model="showPdfUploadDialog" max-width="500px">
            <v-card>
                <v-card-title>PDF Datei hochladen</v-card-title>
                <v-card-text>
                    <v-file-input v-model="selectedPdfFile" label="PDF Datei auswählen" accept="application/pdf" :rules="[v => !v || v.size < 10000000 || 'PDF Datei muss kleiner als 10MB sein']" show-size outlined></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closePdfUploadDialog">Abbrechen</v-btn>
                    <v-btn color="primary" @click="uploadPdf" :loading="uploading" :disabled="!selectedPdfFile">
                        Hochladen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Image Preview Dialog -->
        <template v-if="showImagePreview">
            <v-dialog
                v-model="showImagePreview"
                max-width="1400px"
                persistent
            >
                <v-card>
                    <v-toolbar dark color="primary" dense>
                        <v-btn icon @click="closeImagePreview">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ previewMode === 'crop' ? 'Bild zuschneiden' : 'Bildvorschau' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn-toggle v-model="previewMode" mandatory dense>
                                <v-btn value="preview" small>
                                    <v-icon small>mdi-eye</v-icon>
                                    Vorschau
                                </v-btn>
                                <v-btn value="crop" small>
                                    <v-icon small>mdi-crop</v-icon>
                                    Zuschneiden
                                </v-btn>
                            </v-btn-toggle>
                            
                            <template v-if="previewMode === 'crop'">
                                <v-btn icon small @click="cropperRotateLeft">
                                    <v-icon>mdi-rotate-left</v-icon>
                                </v-btn>
                                <v-btn icon small @click="cropperRotateRight">
                                    <v-icon>mdi-rotate-right</v-icon>
                                </v-btn>
                                <v-btn icon small @click="cropperFlipH">
                                    <v-icon>mdi-flip-horizontal</v-icon>
                                </v-btn>
                                <v-btn icon small @click="cropperFlipV">
                                    <v-icon>mdi-flip-vertical</v-icon>
                                </v-btn>
                                <v-btn icon small @click="cropperReset">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-btn text small @click="saveCrop" :loading="saving" :disabled="saving">
                                    Übernehmen
                                </v-btn>
                            </template>
                            <template v-else>
                                <v-btn text small :href="currentImage" download target="_blank">
                                    <v-icon small left>mdi-download</v-icon>
                                    Download
                                </v-btn>
                            </template>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <div class="preview-container">
                            <!-- Preview Mode -->
                            <template v-if="previewMode === 'preview'">
                                <v-img
                                    :src="getProxiedImageUrl(currentImage)"
                                    contain
                                    max-height="800px"
                                    class="preview-image"
                                ></v-img>
                            </template>

                            <!-- Crop Mode -->
                            <vue-cropper
                                v-else
                                ref="cropper"
                                :src="getProxiedImageUrl(currentImage)"
                                :aspect-ratio="currentAspectRatio"
                                :view-mode="1"
                                :drag-mode="'crop'"
                                :auto-crop-area="0.8"
                                :background="true"
                                :movable="true"
                                :rotatable="true"
                                :scalable="true"
                                :zoomable="true"
                                :center="true"
                                :guides="true"
                                :highlight="true"
                                :crop-box-movable="true"
                                :crop-box-resizable="true"
                                :toggle-drag-mode-on-dblclick="false"
                                :min-container-width="1200"
                                :min-container-height="800"
                                @ready="onCropperReady"
                                @error="onCropperError"
                                class="cropper"
                            ></vue-cropper>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Loading Overlay -->
            <v-overlay :value="saving" absolute>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </template>

        <!-- Loading Overlay -->
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<style scoped>
.max-width-300 {
    max-width: 300px;
}

.v-card {
    transition: all 0.3s ease-in-out;
}

.v-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}

.image-preview-container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    background: #333;
    padding: 8px;
}

.preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px;
}

.preview-wrapper-dialog {
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px;
    border-radius: 4px;
    overflow: hidden;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-wrapper-large {
    height: 500px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
}

.image-dimensions {
    background: rgba(0, 0, 0, 0.5);
    padding: 2px 8px;
    border-radius: 4px;
    margin-top: 8px;
    position: relative;
    z-index: 1;
}

.preview-canvas {
    max-width: 100%;
    max-height: 300px;
    border: 2px solid #42A5F5;
}

.preview-canvas-dialog {
    max-width: 100%;
    max-height: 70vh;
    border: 2px solid #42A5F5;
}

.cropper-container {
    width: 100%;
    height: 800px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.cropper {
    height: 800px !important;
    width: 100%;
    background: #000;
}

.preview-image {
    max-width: 100%;
    height: 800px;
    object-fit: contain;
    background: #ffffff !important;
}

/* Verbesserte Cropper Styles */
.cropper-view-box,
.cropper-face {
    border-radius: 0;
}

.cropper-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.cropper-line {
    background-color: #fff;
}

.cropper-point {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #7957d5;
}

.cropper-view-box {
    outline: 2px solid #7957d5;
}

.cropper-container {
    min-width: 1200px;
    min-height: 800px;
}

/* Zusätzlicher Platz um den Cropper */
.cropper-wrap-box {
    margin: 40px;
}

.cropper-canvas {
    margin: 40px;
}

/* Toolbar Anpassungen */
.v-toolbar__content {
    padding: 0 8px;
}

.v-toolbar__items .v-btn {
    margin: 0 2px;
}

.v-btn-toggle {
    margin: 0 8px;
}

.preview-wrapper {
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
}

.preview-canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.preview-container {
    width: 100%;
    height: 800px;
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.preview-image {
    max-width: 100%;
    height: 800px;
    object-fit: contain;
    background: white !important;
}

.cropper {
    height: 800px !important;
    width: 100%;
}

/* Verbesserte Cropper Styles */
.cropper-view-box,
.cropper-face {
    border-radius: 0;
}

.cropper-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.cropper-line {
    background-color: #fff;
}

.cropper-point {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #7957d5;
}

.cropper-view-box {
    outline: 2px solid #7957d5;
}

/* Druckdatei Preview Styles */
.preview-wrapper {
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px !important;
}

.preview-wrapper img {
    background: white;
}

/* Toolbar Anpassungen */
.v-toolbar__content {
    padding: 0 8px;
}

.v-toolbar__items .v-btn {
    margin: 0 2px;
}

.v-btn-toggle {
    margin: 0 8px;
}
</style>

<script>
import DesignService from '../../services/DesignService'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
    components: {
        VueCropper
    },
    data() {
        return {
            loading: false,
            error: null,
            orderId: '',
            orderDetails: null,
            showSaveButton: false,
            showAllFields: false,
            selectedImage: null,
            imageFiles: {},
            previewMode: 'preview',
            showImagePreview: false,
            currentImage: null,
            currentProduct: null,
            currentField: null,
            currentAspectRatio: 1,
            imageLoaded: false,
            cropperInstance: null,
            saving: false,
            timestamp: Date.now()
        }
    },

    computed: {
        formattedOrderDetails() {
            if (!this.orderDetails) {
                return null;
            }

            const formattedProducts = this.orderDetails.products.map((product) => {
                return {
                    name: product.name,
                    sku: product.sku,
                    attributes: product.attributes,
                    qty: product.qty,
                };
            });

            return {
                orderId: this.orderDetails.orderId,
                shop: this.orderDetails.shop,
                createDate: this.formatDate(this.orderDetails.createDate),
                state: this.orderDetails.state,
                customer: {
                    firstName: this.orderDetails.customer.firstName,
                    lastName: this.orderDetails.customer.lastName,
                },
                shipping: {
                    type: this.orderDetails.shipping.type,
                    shipmentDate: this.formatDate(this.orderDetails.shipping.shipmentDate),
                    lastShipmentDate: this.formatDate(this.orderDetails.shipping.lastShipmentDate),
                },
                products: formattedProducts,
            };
        },

        hasHiddenFields() {
            if (!this.orderDetails || !this.showAllFields) {
                return false;
            }

            return this.orderDetails.products.some(product => {
                if (!product.posterTemplate || !product.design) return false;

                return product.posterTemplate.options.some(option => {
                    // Ein Feld ist versteckt, wenn es keinen Wert hat und nicht angezeigt wird
                    return !product.design.options[option.field] && !this.showAllFields;
                });
            });
        }
    },

    created() {
        this.orderId = this.$route.query.orderId
        console.log("Found order in url: ", this.orderId)
        if (this.orderId) {
            this.fetchOrderDetails()
        }
    },

    watch: {
        previewMode(newMode) {
            if (newMode === 'preview') {
                this.$nextTick(() => {
                    this.drawPreviewImage()
                })
            }
        }
    },

    methods: {
        formatDate(dateString) {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('de-DE');
        },

        getStateColor(state) {
            const stateColors = {
                'UNSHIPPED': 'warning',
                'SHIPPED': 'success',
                'CANCELLED': 'error',
                'PENDING': 'info'
            };
            return stateColors[state] || 'grey';
        },

        async fetchOrderDetails() {
            this.loading = true;
            try {
                const orderDetails = await DesignService.getOrder(this.orderId)
                this.$router.push({ query: { orderId: this.orderId } })

                for (let product of orderDetails.data.products) {
                    if (product.attributes.designId) {
                        product.design = {
                            options: (await DesignService.getDesign(product.attributes.designId)).data,
                            designId: product.attributes.designId,
                            sku: product.sku
                        }

                        // Lade das Poster Template für das Produkt
                        try {
                            const posterTemplate = await DesignService.getPosterTemplate(product.sku)
                            product.posterTemplate = posterTemplate.data
                        } catch (err) {
                            console.error('Fehler beim Laden des Poster Templates:', err)
                        }
                    }
                }

                this.orderDetails = orderDetails.data
            } catch (err) {
                this.error = err.message || 'Fehler beim Laden der Bestellung';
            } finally {
                this.loading = false;
            }
        },

        async saveChanges(design) {
            try {
                this.loading = true;
                const response = await DesignService.saveDesign(design.designId, design);
                const updatedDesign = response.data;

                // Aktualisiere das Design im lokalen State
                for (let product of this.orderDetails.products) {
                    if (product.design && product.design.designId === design.designId) {
                        product.design = {
                            options: updatedDesign.options,
                            designId: updatedDesign.designId,
                            sku: updatedDesign.sku
                        };

                        // Aktualisiere das Vorschaubild
                        if (this.$refs[design.designId] && this.$refs[design.designId][0]) {
                            this.$refs[design.designId][0].src = null;
                            setTimeout(() => {
                                if (this.$refs[design.designId] && this.$refs[design.designId][0]) {
                                    this.$refs[design.designId][0].src = updatedDesign.options.poster + '?' + new Date().getTime();
                                }
                            }, 1500);
                        }
                    }
                }

                this.showSaveButton = false;
                this.$emit('success', 'Änderungen wurden erfolgreich gespeichert');
            } catch (err) {
                this.error = err.message || 'Fehler beim Speichern der Änderungen';
            } finally {
                this.loading = false;
            }
        },

        async designPreviewUrl(designId) {
            try {
                const preview = await DesignService.generateOrder(designId)

                for (let p of this.orderDetails.products) {
                    if (p.attributes['designId'] === designId) {
                        p.design = preview.data
                    }
                }

                return preview.data.options.preview;
            } catch (err) {
                this.error = err.message || 'Fehler beim Generieren der Vorschau';
                return null;
            }
        },

        async importOrder() {
            try {
                this.loading = true;
                await DesignService.importOrder(this.orderId)
                await this.fetchOrderDetails()
                this.$emit('success', 'Bestellung wurde erfolgreich importiert');
            } catch (err) {
                this.error = err.message || 'Fehler beim Importieren der Bestellung';
            } finally {
                this.loading = false;
            }
        },

        // Neue Methoden für PDF Upload
        openPdfUploadDialog(product) {
            this.currentProduct = product;
            this.selectedPdfFile = null;
            this.showPdfUploadDialog = true;
        },

        closePdfUploadDialog() {
            this.showPdfUploadDialog = false;
            this.selectedPdfFile = null;
            this.currentProduct = null;
        },

        async uploadPdf() {
            if (!this.selectedPdfFile || !this.currentProduct) return;

            try {
                this.uploading = true;
                const formData = new FormData();
                formData.append('pdf', this.selectedPdfFile);
                formData.append('designId', this.currentProduct.design.designId);

                await DesignService.uploadPdf(formData);

                // Aktualisiere die Vorschau nach dem Upload
                await this.designPreviewUrl(this.currentProduct.design.designId);

                this.closePdfUploadDialog();
                this.$emit('success', 'PDF wurde erfolgreich hochgeladen');
            } catch (err) {
                this.error = err.message || 'Fehler beim Hochladen der PDF-Datei';
            } finally {
                this.uploading = false;
            }
        },

        getImageDimensions(option) {
            if (option.width && option.height) {
                // Speichere die Dimensionen für späteren Zugriff
                this.templateDimensions = {
                    width: Math.round(option.width),
                    height: Math.round(option.height)
                }
                return `Empfohlene Größe: ${Math.round(option.width)}x${Math.round(option.height)} Pixel`
            }
            return ''
        },

        initCropper() {
            console.log('Initializing cropper...')
            if (this.$refs.cropper) {
                setTimeout(() => {
                    this.cropperInstance = this.$refs.cropper
                    if (this.cropperInstance) {
                        // Finde die aktuellen Template-Optionen
                        const option = this.currentProduct?.posterTemplate?.options?.find(opt => opt.field === this.currentField)
                        if (option && option.width && option.height) {
                            const ratio = option.width / option.height
                            console.log(`Setting aspect ratio to ${ratio} (${option.width}x${option.height})`)
                            this.cropperInstance.setAspectRatio(ratio)
                        }
                    }
                    console.log('Cropper initialized')
                }, 100)
            } else {
                console.error('Cropper reference not found')
            }
        },

        getCropperInstance() {
            return this.$refs.cropper
        },

        cropperRotateLeft() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.rotate(-90)
            }
        },

        cropperRotateRight() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.rotate(90)
            }
        },

        cropperFlipH() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                const scaleX = cropper.getData().scaleX || 1
                cropper.scaleX(-scaleX)
            }
        },

        cropperFlipV() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                const scaleY = cropper.getData().scaleY || 1
                cropper.scaleY(-scaleY)
            }
        },

        cropperReset() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.reset()
            }
        },

        openImagePreview(imageUrl, product, field, aspectRatio) {
            console.log('Opening preview with:', { imageUrl, product, field, aspectRatio })
            
            // Finde die korrekten Dimensionen aus dem Template
            let ratio = 1
            if (product.posterTemplate && product.posterTemplate.options) {
                const option = product.posterTemplate.options.find(opt => opt.field === field)
                if (option) {
                    console.log('Found template option:', option)
                    if (option.width && option.height) {
                        ratio = option.width / option.height
                        console.log(`Calculated aspect ratio from dimensions: ${option.width}x${option.height} = ${ratio}`)
                    }
                }
            }
            
            this.currentImage = this.getProxiedImageUrl(imageUrl)
            this.currentProduct = product
            this.currentField = field
            this.currentAspectRatio = ratio
            this.showImagePreview = true
            this.previewMode = 'preview'
            this.imageLoaded = false
            this.cropperInstance = null
        },

        closeImagePreview() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.destroy()
            }
            this.showImagePreview = false
            this.currentImage = null
            this.currentProduct = null
            this.currentField = null
            this.imageLoaded = false
            this.cropperInstance = null
        },

        drawPreviewImage() {
            const canvas = this.$refs.previewCanvas
            if (!canvas) return

            const ctx = canvas.getContext('2d')
            const img = new Image()
            img.crossOrigin = 'anonymous'
            img.src = this.getProxiedImageUrl(this.currentImage)

            img.onload = () => {
                // Setze Canvas-Größe
                canvas.width = 1200
                canvas.height = 800

                // Zeichne den gekachelten Hintergrund
                this.drawCheckerPattern(ctx, canvas.width, canvas.height)

                // Berechne die Bildproportionen
                const scale = Math.min(
                    canvas.width / img.naturalWidth,
                    canvas.height / img.naturalHeight
                )

                const scaledWidth = img.naturalWidth * scale
                const scaledHeight = img.naturalHeight * scale

                // Zentriere das Bild
                const x = (canvas.width - scaledWidth) / 2
                const y = (canvas.height - scaledHeight) / 2

                // Zeichne das Bild
                ctx.drawImage(img, x, y, scaledWidth, scaledHeight)
            }
        },


        async saveCrop() {
            const cropper = this.getCropperInstance()
            if (!cropper || !this.currentProduct || !this.currentField) {
                console.error('Missing required data for saving crop')
                return
            }

            try {
                this.saving = true
                const canvas = cropper.getCroppedCanvas({
                    maxWidth: 4096,
                    maxHeight: 4096,
                    fillColor: '#fff',
                    imageSmoothingEnabled: true,
                    imageSmoothingQuality: 'high'
                })

                if (!canvas) {
                    throw new Error('Failed to create canvas')
                }

                const blob = await new Promise((resolve, reject) => {
                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob)
                        } else {
                            reject(new Error('Failed to create blob'))
                        }
                    }, 'image/jpeg', 0.95)
                })

                const formData = new FormData()
                formData.append('image', blob, 'cropped.jpg')
                formData.append('designId', this.currentProduct.design.designId)
                formData.append('field', this.currentField)

                const response = await DesignService.uploadCroppedImage(formData)
                
                if (response.data && response.data.url) {
                    // Aktualisiere den Timestamp
                    this.timestamp = Date.now()
                    
                    // Aktualisiere das Design
                    await this.reloadDesign()
                    
                    this.$emit('success', 'Bild wurde erfolgreich zugeschnitten')
                }

                this.closeImagePreview()
            } catch (error) {
                console.error('Error during crop process:', error)
                this.error = 'Fehler beim Zuschneiden des Bildes'
            } finally {
                this.saving = false
            }
        },

        async reloadDesign() {
            try {
                // Lade das komplette Design neu
                const response = await DesignService.getDesign(this.currentProduct.design.designId)
                if (response.data) {
                    // Aktualisiere die Design-Optionen
                    this.currentProduct.design.options = response.data.options

                    // Warte auf DOM-Update
                    await this.$nextTick()

                    // Initialisiere alle Canvas-Elemente neu
                    if (this.currentProduct.posterTemplate) {
                        this.currentProduct.posterTemplate.options.forEach(option => {
                            if (option.type === 'image') {
                                this.initCanvas(this.currentProduct.design.designId, option.field)
                            }
                        })
                    }

                    // Erzwinge Neurendering der Vorschaubilder
                    await this.designPreviewUrl(this.currentProduct.design.designId)
                }
            } catch (error) {
                console.error('Error reloading design:', error)
            }
        },

        watch: {
            previewMode(newMode) {
                if (newMode === 'preview') {
                    this.$nextTick(() => {
                        this.drawPreviewImage()
                    })
                }
            }
        },

        onCropperReady() {
            console.log('Cropper is ready');
            this.imageLoaded = true;
        },

        onCropperError(error) {
            console.error('Cropper error:', error)
            this.error = 'Fehler beim Laden des Croppers: ' + error.message
        },

        // Erweitere handleImageUpload
        async handleImageUpload(file, field, product) {
            if (!file) return;

            try {
                this.loading = true;
                const imageUrl = URL.createObjectURL(file);
                product.design.options[field] = imageUrl;

                // Zeichne das neue Bild
                this.$nextTick(() => {
                    this.drawImageOnCanvas(imageUrl, 'canvas-' + field, 300, 300);
                });

                await this.saveChanges(product.design.options);
            } catch (err) {
                this.error = err.message || 'Fehler beim Hochladen des Bildes';
            } finally {
                this.loading = false;
            }
        },

        async removeImage(field, product) {
            try {
                this.loading = true;
                product.design.options[field] = null;
                // Speichere die Änderungen
                await this.saveChanges(product.design.options);
                this.$emit('success', 'Bild wurde erfolgreich entfernt');
            } catch (err) {
                this.error = err.message || 'Fehler beim Entfernen des Bildes';
            } finally {
                this.loading = false;
            }
        },

        copyImageUrl() {
            navigator.clipboard.writeText(this.selectedImage)
                .then(() => {
                    this.$emit('success', 'URL wurde in die Zwischenablage kopiert');
                })
                .catch(() => {
                    this.error = 'Fehler beim Kopieren der URL';
                });
        },

        getCanvasId(designId, field) {
            return `canvas-${designId}-${field}`;
        },

        getImageId(designId, field) {
            return `img-${designId}-${field}`;
        },

        initCanvas(designId, field) {
            const canvas = document.getElementById(this.getCanvasId(designId, field))
            const img = document.getElementById(this.getImageId(designId, field))

            if (!canvas || !img) {
                console.warn('Canvas or image not found for:', { designId, field })
                return
            }

            const ctx = canvas.getContext('2d')
            if (!ctx) {
                console.warn('Could not get canvas context for:', { designId, field })
                return
            }

            const wrapper = canvas.parentElement
            if (!wrapper) {
                console.warn('Could not find wrapper element for:', { designId, field })
                return
            }

            canvas.width = wrapper.offsetWidth
            canvas.height = wrapper.offsetHeight

            // Zeichne zuerst den weißen Hintergrund
            ctx.fillStyle = '#ffffff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            // Dann den gekachelten Hintergrund
            this.drawCheckerPattern(ctx, canvas.width, canvas.height)

            if (!img.complete) {
                img.onload = () => this.drawCanvasContent(ctx, img, canvas)
            } else {
                this.drawCanvasContent(ctx, img, canvas)
            }
        },

        drawCanvasContent(ctx, img, canvas) {
            try {
                const scale = Math.min(
                    canvas.width / img.naturalWidth,
                    canvas.height / img.naturalHeight
                )

                const scaledWidth = img.naturalWidth * scale
                const scaledHeight = img.naturalHeight * scale

                const x = (canvas.width - scaledWidth) / 2
                const y = (canvas.height - scaledHeight) / 2

                // Zeichne das Bild mit weißem Hintergrund
                ctx.fillStyle = '#ffffff'
                ctx.fillRect(x, y, scaledWidth, scaledHeight)
                ctx.drawImage(img, x, y, scaledWidth, scaledHeight)

                // Zeichne den Rahmen
                ctx.strokeStyle = '#00A0FF'
                ctx.lineWidth = 2
                ctx.strokeRect(x, y, scaledWidth, scaledHeight)
            } catch (error) {
                console.error('Error drawing canvas content:', error)
            }
        },

        drawCheckerPattern(ctx, width, height) {
            try {
                const squareSize = 20;
                ctx.fillStyle = '#808080';
                ctx.fillRect(0, 0, width, height);

                ctx.fillStyle = '#666666';
                for (let x = 0; x < width; x += squareSize * 2) {
                    for (let y = 0; y < height; y += squareSize * 2) {
                        ctx.fillRect(x, y, squareSize, squareSize);
                        ctx.fillRect(x + squareSize, y + squareSize, squareSize, squareSize);
                    }
                }
            } catch (error) {
                console.error('Error drawing checker pattern:', error);
            }
        },

        // Neue Methode zum Proxyen der Bilder
        getProxiedImageUrl(url) {
            if (!url) return ''
            // Wenn die URL bereits eine Proxy-URL ist, nicht nochmal umwandeln
            if (url.startsWith('/public/images/proxy/')) return url
            // Wenn die URL bereits eine relative URL ist, verwende sie direkt
            if (url.startsWith('/')) return url
            
            // Extrahiere den Dateinamen aus der S3-URL
            const filename = url.split('/').pop()
            // Verwende unseren eigenen Server als Proxy
            return `/public/images/proxy/${(filename)}`
        }
    }
}
</script>
