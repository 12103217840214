<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-alert type="error" v-if="error" :value="true">
                            {{ error }}</v-alert>
                        <v-card-title>
                            <h1>Poster erstellen</h1>
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="poster.title" label="Titel" required></v-text-field>
                                <v-text-field v-model="poster.SKU" label="SKU" required></v-text-field>
                                <v-text-field v-model="poster.additionalSkus" label="Weitere SKUs (durch Komma getrennt)"></v-text-field>
                                
                                

                                <v-text-field v-model="poster.tags" label="Tags" required></v-text-field>
                                <v-file-input label="SVG" accept="image/svg+xml" show-size @change="processUpload($event)"></v-file-input>

                                <!-- Select Preview Picture -->
                                <v-select
                                    v-model="poster.picture"
                                    :items="pictures"
                                    item-text="image"
                                    label="Vorschaubild"
                                    required>

                                    <!-- Template show image on option-->
                                    <template v-slot:item="{ item }">
                                        <v-img :src="item.image" width="80" contain height="80"></v-img>
                                        </template>
                                </v-select>

                                <v-checkbox v-model="filter.wood" label="Holzgravur"></v-checkbox>

                                <!-- PDF Output Size optional -->
                                <v-text-field v-model="outputSize.width" label="Breite (mm/px)" type="text"></v-text-field>
                                <v-text-field v-model="outputSize.height" label="Höhe (mm/px)" type="text"></v-text-field>

                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :loading="loading" @click="edit ? updatePoster() : createPoster()">Weiter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title>
                            <h1>Vorschau</h1>
                        </v-card-title>
                        <v-card-text>
                            <SVGImage v-if="poster.svg" :src="poster.svg" width="400" height="600"></SVGImage>
                            <SVGImage v-if="poster.thumbnail" :src="poster.thumbnail" width="400" height="600"></SVGImage>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AuthCheck />
    </div>
</template>

<script>
import PosterService from '../../services/PosterService'
import AuthCheck from '../../components/AuthCheck.vue'
import SVGImage from '../../components/SVGImage.vue';
import PictureService from '../../services/PictureService';

export default {
    components: { AuthCheck, SVGImage },
    data: () => ({
        poster: {
            title: '',
            SKU: '',
            tags: '',
            svg: '',
        },
        edit: false,
        uploadDialog: true,
        loading: false,
        error: null,
        pictures: [],
        filter: {
            wood: false,
        },
        outputSize: {
            width: "",
            height: ""
        }
    }),
    mounted() {
        console.log(this.$route.query.id)
        this.getPictures()
        if (this.$route.query.id) {
            this.edit = true;
            this.getPoster(this.$route.query.id)
        }
    },
    methods: {

        async getPictures() {
            this.loading = true
            try {
                this.pictures = (await PictureService.getPictures()).data;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

        processUpload(file) {
            console.log(file)
            let read = new FileReader();
            read.readAsBinaryString(file);
            read.onloadend = () => {
                console.log(read.result)
                this.poster.svg = read.result;
            };
        },

        async loadPreview(item) {
            return await fetch(`https://svg.us.accelery.host/public/product/${item.SKU}/preview`, {
                headers: {
                    "x-api-key": "ayUEHgX1TV9h5X6l2ugz2MxP4NiClHVojB2rPpOL002bFd3MSfw3apX5lPp9"
                }
            }).then(res => res.text())


        },

        async getPoster(id) {
            this.loading = true
            try {
                let response = (await PosterService.getPoster(id)).data;

                this.loadPreview(response).then(x=> this.poster.thumbnail = x)

                //set filter and output width
                this.filter.wood = response.picture.filter == 'wood' ? true : false;
                this.outputSize.width = response.outputSize?.width || '';
                this.outputSize.height = response.outputSize?.height || '';

                this.poster = response
                console.log(this.poster)
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },
        async createPoster() {
            this.loading = true
            try {

                this.tags = this.poster.tags.split(',')

                //check if picture is string or object
                if (typeof this.poster.picture == 'string' && this.poster.picture.indexOf("http") == 0) {
                    this.poster.picture = this.pictures.filter(x => x.image == this.poster.picture)[0]

                }
                this.poster.picture.filter = this.filter.wood ? "wood" : null;

                if(this.outputSize.width && this.outputSize.height){
                    this.poster.outputSize = this.outputSize
                }


                let response = (await PosterService.createPoster(this.poster)).data;
                console.log(response)
                if (response.acknowledged) {

                    let id = response.insertedId;
                    this.$router.push('/poster/create/editor?id=' + id)
                } else {
                    this.error = 'Poster konnte nicht erstellt werden'
                }
            } catch (err) {
                console.log(err);
                let errormsg = 'Poster konnte nicht erstellt werden'
                if (err.response && err.response.data && err.response.data.error) {
                    errormsg = err.response.data.error
                }
                this.error = errormsg
            } finally {
                this.loading = false
            }
        },
        async updatePoster() {
            this.loading = true
            try {
                this.tags = this.poster.tags.split(',')

                //check if picture is string or object
                if (typeof this.poster.picture == 'string' && this.poster.picture.indexOf("http") == 0) {
                    this.poster.picture = this.pictures.filter(x => x.image == this.poster.picture)[0]

                }
                this.poster.picture.filter = this.filter.wood ? "wood" : null;

                if(this.outputSize.width && this.outputSize.height){
                    this.poster.outputSize = this.outputSize
                }

                let response = (await PosterService.updatePoster(this.poster._id, this.poster)).data;
                console.log(response)
                if (response.acknowledged) {
                    this.$router.push('/poster/create/editor?id=' + this.poster._id)
                } else {
                    this.error = 'Poster konnte nicht gespeichert werden'
                }
            } catch (err) {
                let errormsg = 'Poster konnte nicht gespeichert werden'
                if (err.response && err.response.data && err.response.data.error) {
                    errormsg = err.response.data.error
                }
                this.error = errormsg
            } finally {
                this.loading = false
            }
        },

    },
}
</script>

<style></style>