import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Logout from '../views/Logout'
import Editor from '../views/poster/PosterEditor'
import PosterOverview from '../views/poster/PosterOverview'
import PosterCreate from '../views/poster/PosterCreate'
import PictureOverview from '../views/pictures/PictureOverview'
import PictureCreate from '../views/pictures/PictureCreate'
import DesignsOverview from '../views/designs/DesignOverview'
import OrdersOverview from '../views/orders/OrdersOverview'
import ConfiguratorPreview from '../views/ConfiguratorPreview'
import OrderEditor from '../views/orders/OrderEditor'
import FontManager from '../views/fonts/FontManager'
import FailQueue from '../views/designs/FailQueue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: Login
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/poster',
    alias: "/",
    name: 'Posters',
    component: PosterOverview
  },
  {
    path: '/poster/create',
    name: 'Create Poster',
    component: PosterCreate
  },
  {
    path: '/pictures',
    name: 'Pictures',
    component: PictureOverview
  },
  {
    path: '/pictures/create',
    name: 'Create Picture',
    component: PictureCreate
  },
  {
    path: '/designs',
    name: 'Designs',
    component: DesignsOverview
  },
  {
    path: '/orders',
    name: 'Bestellungen',
    component: OrdersOverview
  },
  {
    path: '/poster/create/editor',
    name: 'Create Poster',
    component: Editor
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: ConfiguratorPreview
  },
  {
    path: '/order/configurator',
    name: 'Order Configurator',
    component: OrderEditor
  },
  {
    path: '/fontmanager',
    name: 'Fonts',
    component: FontManager
  },
  {
    path: '/failqueue',
    name: 'Fehlerhafte Designs',
    component: FailQueue
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
