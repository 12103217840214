<template>
  <div>
    <v-container>
      <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>

      <v-card class="mb-4">
        <v-card-title>Filter & Sortierung</v-card-title>
        <v-card-text>
          <v-row>
            <!-- Date Range -->
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFrom"
                        label="Datum von"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      no-title
                      @input="dateMenu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateTo"
                        label="Datum bis"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateTo"
                      no-title
                      @input="dateMenu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- Order ID Filter -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="orderIdFilter"
                label="Bestellnummer"
                clearable
                @input="debouncedFetchOrders"
              ></v-text-field>
            </v-col>

            <!-- Status Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="checkedFilter"
                :items="[
                  { text: 'Alle Bestellungen', value: 'all' },
                  { text: 'Kontrolliert', value: 'checked' },
                  { text: 'Nicht kontrolliert', value: 'unchecked' }
                ]"
                label="Kontrollstatus"
                @change="fetchOrders"
              ></v-select>
            </v-col>

            <!-- Sort Direction -->
            <v-col cols="12" md="3">
              <v-select
                v-model="sortDirection"
                :items="[
                  { text: 'Neueste zuerst', value: 'desc' },
                  { text: 'Älteste zuerst', value: 'asc' }
                ]"
                label="Sortierung"
                @change="fetchOrders"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Apply Filters Button -->
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" @click="fetchOrders">
                Filter anwenden
              </v-btn>
              <v-btn class="ml-2" @click="resetFilters">
                Filter zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col v-for="order in paginatedOrders" :key="order.orderId" cols="12">
          <v-card :loading="loading">
            <v-card-title class="d-flex justify-space-between">
              <div>
                Bestellung #{{ order.orderId }}
              </div>
              <v-btn
                :color="order.isChecked ? 'success' : 'grey'"
                @click="toggleCheckedStatus(order)"
                class="ml-2"
              >
                <v-icon left>{{ order.isChecked ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
                {{ order.isChecked ? 'Kontrolliert' : 'Nicht kontrolliert' }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-chip-group>
                <v-chip v-for="tag in order.state.split(',')" :key="tag" small>
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              
              <v-divider class="my-3"></v-divider>

              <div v-for="product in order.products" :key="product.design.designId">
                <v-row>
                  <v-col cols="6">
                    <v-img
                      :src="product.design.poster"
                      contain
                      class="mb-2"
                    ></v-img>
                    <p class="mb-0"><strong>{{ product.name }}</strong></p>
                  </v-col>
                  <v-col cols="6">
                    <v-list dense>
                      <v-list-item v-for="(value, key) in product.attributes" :key="key">
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            {{ key }}:<strong style="background-color: #f0f0f0; padding: 2px; border-radius: 5px; color: #000;"> {{ value }} </strong>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" :href="`https://svg.us.accelery.host/order/configurator?orderId=${order.orderId}`">
                Bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- Pagination -->
      <v-row justify="center" class="mt-4">
        <v-col cols="8">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="fetchOrders"
          ></v-pagination>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="itemsPerPage"
            :items="[20, 50, 100]"
            label="Items pro Seite"
            @change="handleItemsPerPageChange"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>

    <!-- Detail Dialog -->
    <v-dialog v-model="selectedOrder" max-width="900px">
      <v-card v-if="selectedOrder">
        <v-card-title>Bestelldetails #{{ selectedOrder.orderId }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Bestellnummer" v-model="selectedOrder.orderId" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="plentyId" v-model="selectedOrder.plentyId" readonly></v-text-field>
            </v-col>
          </v-row>
          <!-- Rest der Detailansicht bleibt wie im ursprünglichen Code -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="selectedOrder = null">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AuthCheck />
  </div>
</template>

<script>
import DesignService from "../../services/DesignService";
import AuthCheck from "../../components/AuthCheck.vue";
import AuthService from "../../services/AuthService";
import _ from 'lodash';

export default {
  components: { AuthCheck },
  data: () => ({
    designs: [],
    paginatedOrders: [],
    loading: false,
    error: null,
    user: null,
    selectedOrder: null,
    // Pagination
    currentPage: 1,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
    // Filter
    dateFrom: null,
    dateTo: null,
    dateMenu1: false,
    dateMenu2: false,
    orderIdFilter: '',
    sortDirection: 'desc',
    checkedFilter: 'all',
  }),

  async created() {
    try {
      this.user = (await AuthService.getUser()).data;
      this.debouncedFetchOrders = _.debounce(this.fetchOrders, 500);
      await this.fetchOrders();
    } catch (err) {
      console.error(err);
      if (!this.user) this.$router.push("/auth/login");
    }
  },

  methods: {
    async fetchOrders() {
      this.loading = true;
      try {
        const response = await DesignService.getOrders({
          page: this.currentPage,
          limit: this.itemsPerPage,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          orderId: this.orderIdFilter,
          sort: this.sortDirection,
          checked: this.checkedFilter
        });
        
        this.paginatedOrders = response.data;
        // Angenommen, die API liefert auch Pagination-Informationen
        // this.totalItems = response.data.pagination.totalItems;
        // this.totalPages = response.data.pagination.totalPages;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },

    showItem(order) {
      this.selectedOrder = order;
    },

    async generateOrder(designId) {
      this.loading = true;
      try {
        await DesignService.generateOrder(designId);
        if (this.selectedOrder) {
          const updatedOrder = await DesignService.getOrder(this.selectedOrder.orderId);
          this.selectedOrder = updatedOrder.data;
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },

    resetFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.orderIdFilter = '';
      this.sortDirection = 'desc';
      this.checkedFilter = 'all';
      this.currentPage = 1;
      this.fetchOrders();
    },

    handleItemsPerPageChange() {
      this.currentPage = 1;
      this.fetchOrders();
    },

    async toggleCheckedStatus(order) {
      this.loading = true;
      try {
        await DesignService.updateOrderCheckedStatus(order.orderId, !order.isChecked);
        order.isChecked = !order.isChecked;
        await this.fetchOrders(); 
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
.customer-info {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.87);
}

/* Neue Styles für den Check-Button */
.v-btn.checked {
  background-color: var(--v-success-base) !important;
  color: white !important;
}
</style>