<template>
  <div>
    <v-container>
      <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>

      <v-card class="mb-4">
        <v-card-title>Filter & Sortierung</v-card-title>
        <v-card-text>
          <v-row>
            <!-- Date Range -->
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFrom"
                        label="Datum von"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      no-title
                      @input="dateMenu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateTo"
                        label="Datum bis"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateTo"
                      no-title
                      @input="dateMenu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- SKU Filter -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="skuFilter"
                label="SKU Filter"
                clearable
                @input="debouncedFetchDesigns"
              ></v-text-field>
            </v-col>

            <!-- Sort Direction -->
            <v-col cols="12" md="3">
              <v-select
                v-model="sortDirection"
                :items="[
                  { text: 'Neueste zuerst', value: 'desc' },
                  { text: 'Älteste zuerst', value: 'asc' }
                ]"
                label="Sortierung"
                @change="fetchDesigns"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Apply Filters Button -->
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" @click="fetchDesigns">
                Filter anwenden
              </v-btn>
              <v-btn class="ml-2" @click="resetFilters">
                Filter zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
     
      <v-row>
        <v-col v-for="design of paginatedDesigns" :key="design.designId" cols="4">
          <v-card :loading="loading">
            <v-card-text>
            <v-text-field v-model="design.orderId" label="Order ID" disabled="true"></v-text-field>
            <v-text-field v-model="design.sku" label="SKU" disabled="true"></v-text-field>
        
            <v-img
              :src="design.poster"
              height="auto"
              width="100%"
              contain
            ></v-img>
          </v-card-text>
    
            <v-card-actions>
              <v-btn color="Primary" @click="downloadPoster(design)">Download</v-btn>
            </v-card-actions>
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>

      <!-- Pagination -->
      <v-row justify="center" class="mt-4">
        <v-col cols="8">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="fetchDesigns"
          ></v-pagination>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="itemsPerPage"
            :items="[20, 50, 100]"
            label="Items pro Seite"
            @change="handleItemsPerPageChange"
          ></v-select>
        </v-col>
      </v-row>

    </v-container>
    <AuthCheck />
  </div>
</template>

<script>
import DesignService from "../../services/DesignService";
import AuthCheck from "../../components/AuthCheck.vue";
import AuthService from "../../services/AuthService";
import _ from 'lodash';

export default {
  components: { AuthCheck },
  data: () => ({
    designs: [],
    paginatedDesigns: [],
    loading: false,
    error: null,
    user: null,
    search: "",
    testFile: null,
    // Pagination
    currentPage: 1,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
    dateFrom: null,
    dateTo: null,
    dateMenu1: false,
    dateMenu2: false,
    skuFilter: '',
    sortDirection: 'desc',
  }),

  async created() {
    try {
      this.user = (await AuthService.getUser()).data;
      this.debouncedFetchDesigns = _.debounce(this.fetchDesigns, 500);
    } catch (err) {
      console.error(err);
      if (!this.user) this.$router.push("/auth/login");
    }
  },

  async mounted() {
  },

  methods: {
    async fetchDesigns() {
      this.loading = true;
      try {
        const response = await DesignService.getDesigns({
          page: this.currentPage,
          limit: this.itemsPerPage,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          sku: this.skuFilter,
          sort: this.sortDirection
        });

        this.paginatedDesigns = response.data.designs;
        this.totalItems = response.data.pagination.totalItems;
        this.totalPages = response.data.pagination.totalPages;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(item) {
      this.loading = true;
      try {
        await DesignService.deleteDesign(item.designId);
        // Nach dem Löschen die aktuelle Seite neu laden
        await this.fetchDesigns();
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    async downloadPoster(item) {
      window.open(item.poster, '_blank');
    },

    resetFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.skuFilter = '';
      this.sortDirection = 'desc';
      this.currentPage = 1;
      this.fetchDesigns();
    },

  }
};
</script>

<style></style>