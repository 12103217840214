import Api from '@/services/Api'

export default {
    /**
     * Holt alle Einträge aus der Failqueue
     * @returns {Promise}
     */
    getFailQueueItems() {
        return Api().get('fail-queue')
    },

    /**
     * Versucht einen fehlgeschlagenen Eintrag erneut zu verarbeiten
     * @param {String} id - Die ID des Failqueue-Eintrags
     * @returns {Promise}
     */
    retryProcessing(id) {
        return Api().post(`fail-queue/${id}/retry`)
    },

    /**
     * Entfernt einen Eintrag aus der Failqueue
     * @param {String} id - Die ID des zu löschenden Eintrags
     * @returns {Promise}
     */
    removeFromQueue(id) {
        return Api().delete(`fail-queue/${id}`)
    },

    /**
     * Holt die Details eines bestimmten Failqueue-Eintrags
     * @param {String} id - Die ID des gewünschten Eintrags
     * @returns {Promise}
     */
    getFailQueueItem(id) {
        return Api().get(`fail-queue/${id}`)
    },

    /**
     * SVG für ein Design herunterladen
     * @param {String} sku - Die SKU des Designs
     * @returns {Promise}
     */
    downloadSVG(sku) {
        return Api().get(`poster/svg/${sku}`, { responseType: 'blob' })
    },

    /**
     * SVG für ein Design hochladen
     * @param {String} sku - Die SKU des Designs
     * @param {File} svgFile - Die SVG-Datei
     * @returns {Promise}
     */
    uploadSVG(sku, svgFile) {
        const formData = new FormData()
        formData.append('svg', svgFile)
        return Api().post(`poster/svg/${sku}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    /**
     * Design-Optionen aktualisieren
     * @param {String} designId - Die Design-ID
     * @param {Object} options - Die aktualisierten Optionen
     * @returns {Promise}
     */
    updateDesignOptions(designId, options) {
        return Api().put(`designs/${designId}`, options)
    }
} 