/* eslint-disable */
import Cropper from 'cropperjs'
class AcceleryConfigurator {
    constructor() {
        this.api = "https://svg.us.accelery.host/public/";
        this.poster = null;
        this.designId = null;;
        this.sku = null;
        this.loading = false;
        this.tryToLoad = 0;
        this.initConfigurator = false;
        this.init();
    }

    setSku(sku) {
        this.initConfigurator = false;
        this.sku = sku;
        this.designId = "ACCELERY-TEST-" + Math.random().toString(36).substring(7);
        this.init();
    }

    getDesignId() {
        return this.designId;
    }

    init() {
        if (this.sku != null) {
            this.loadPoster();
        } else {
            setTimeout(() => {
                this.init();
            }, 100);
        }
    }

    loadPoster() {
        document.getElementById("poster-loading").style.display = "block";
        fetch(this.api + "product/" + this.sku).then((response) => {
            return response.json();
        }).then((data) => {
            this.poster = data;
            this.initConfigurator = true;
            this.loadCustomOptions();
            this.previewPoster(true);
        })
    }

    loadCustomOptions() {
        if (!this.initConfigurator) return;
        if (this.poster && this.poster.options) {

            //clear id poster-options
            const posterOptions = document.getElementById("poster-options");
            posterOptions.innerHTML = "";

            // add hidden input for designId
            const designIdInput = document.createElement("input");
            designIdInput.type = "hidden";
            designIdInput.name = "properties[designId]";
            designIdInput.id = "designId";
            designIdInput.value = this.designId;
            posterOptions.appendChild(designIdInput);

            let self = this;

            //sort poster.options to put image options first
            this.poster.options.sort((a, b) => {
                if (a.type == "image" && b.type != "image") {
                    return -1;
                } else if (a.type != "image" && b.type == "image") {
                    return 1;
                } else {
                    return 0;
                }
            });
            for (let option of this.poster.options) {

                if (option.type == "image") {

                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--image-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--image-option");
                    label.setAttribute("for", "product-form__image-option-" + option.field);
                    label.innerHTML = option.label;

                    //image upload
                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--image-option");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.setAttribute("id", "product-form__image-option-" + option.field);
                    input.setAttribute("name", "product-form__image-option-" + option.field);
                    input.addEventListener("change", function () {
                        self.previewPoster();
                    });
                    input.setAttribute("required", "");

                    //add preview of selected image ontop
                    const preview = document.createElement("img");
                    preview.classList.add("product-form__image-option-preview");
                    preview.setAttribute("id", "product-form__image-option-preview-" + option.field);
                    preview.setAttribute("src", "");

                    preview.style.display = "none";
                    preview.style.border = "1px solid #e5e5e5";
                    preview.style.borderRadius = "5px";
                    preview.style.padding = "5px";
                    preview.style.boxShadow = "0 0 5px 0 rgba(0,0,0,0.2), 0 5px 5px 0 rgba(0,0,0,0.24)";
                    preview.style.maxWidth = "200px";
                    preview.style.marginTop = "10px";
                    preview.style.marginBottom = "10px";

                    //set data-aspect-ratio attribute
                    if (option.aspectRatio) {
                        preview.setAttribute("data-aspect-ratio", option.aspectRatio);
                    }

                    div.appendChild(label);
                    div.appendChild(input);
                    div.appendChild(preview);

                    let self = this;

                    //show image once selected
                    input.addEventListener("change", function () {

                        if (this.files && this.files[0]) {
                            var reader = new FileReader();
                            reader.onload = function (e) {

                                preview.src = e.target.result;
                                preview.style.display = "block";
                                option.src = e.target.result; // directly use the selected image
                                self.previewPoster();
                            }
                            reader.readAsDataURL(this.files[0]);
                        }
                    });

                    posterOptions.appendChild(div);

                }

                if (option.type == "color") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--color-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--color-option");
                    label.setAttribute("for", "product-form__color-option-" + option.field);
                    label.innerHTML = option.label;

                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--color-option");
                    input.setAttribute("type", "color");
                    input.setAttribute("id", "product-form__color-option-" + option.field);
                    input.setAttribute("name", "product-form__color-option-" + option.field);
                    input.onchange = self.previewPoster;
                    input.setAttribute("required", "");
                    input.addEventListener("change", function () {
                        self.previewPoster(false);
                    });
                    //set placeholder color
                    if (option.placeholder) {
                        input.setAttribute("placeholder", option.placeholder);
                        input.value = option.placeholder;
                    }

                    div.appendChild(label);
                    div.appendChild(input);

                    posterOptions.appendChild(div);
                }

                if (option.type == "fontSet") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--font-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--font-option");
                    label.setAttribute("for", "product-form__font-option-" + option.field);
                    label.innerHTML = option.label;

                    const select = document.createElement("select");
                    select.classList.add("product-form__input");
                    select.classList.add("product-form__input--font-option");
                    select.setAttribute("id", "product-form__font-option-" + option.field);
                    select.setAttribute("name", "properties[" + option.field + "]");
                    select.setAttribute("required", true);

                    select.addEventListener("change", function () {
                        self.previewPoster(false);
                    });

                    // add font options
                    for (let font of option.fontSet.fonts) {
                        const optionElement = document.createElement("option");
                        optionElement.setAttribute("value", font.id);
                        optionElement.innerHTML = font.name;
                        //set optionElement fontFamily

                        this.loadFont(font.name)
                        select.appendChild(optionElement);
                    }

                    div.appendChild(label);
                    div.appendChild(select);

                    posterOptions.appendChild(div);
                }


                if (option.type == "text") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--text-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--text-option");
                    label.setAttribute("for", "product-form__text-option-" + option.field);
                    label.innerHTML = option.label;

                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--text-option");
                    input.setAttribute("type", "text");
                    input.setAttribute("id", "product-form__text-option-" + option.field);
                    input.setAttribute("name", "properties[" + option.field + "]");
                    input.setAttribute("maxlength", option.maxChar);
                    //add placeholder
                    input.setAttribute("value", option.placeholder);
                    input.setAttribute("placeholder", option.placeholder);
                    input.setAttribute("required", true);

                    //change event
                    input.addEventListener("change", function () {
                        self.previewPoster(false);
                    });

                    div.appendChild(label);
                    div.appendChild(input);

                    posterOptions.appendChild(div);
                }
                if (option.type == "mapbox") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--text-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--text-option");
                    label.setAttribute("for", "product-form__text-option-" + option.field);
                    label.innerHTML = option.label;

                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--text-mapbox");
                    input.setAttribute("type", "text");
                    input.setAttribute("id", "product-form__text-mapbox-" + option.field);
                    input.setAttribute("name", "properties[" + option.field + "]");
                    //add placeholder
                    input.setAttribute("required", true);

                    //change event
                    input.addEventListener("change", function () {
                        self.previewPoster(false);
                    });

                    div.appendChild(label);
                    div.appendChild(input);

                    posterOptions.appendChild(div);
                }
                if (option.type == "imageLink") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--text-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--text-option");
                    label.setAttribute("for", "product-form__text-option-" + option.field);
                    label.innerHTML = option.label;

                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--text-imageLink");
                    input.setAttribute("type", option.inputType);
                    input.setAttribute("id", "product-form__text-imageLink-" + option.field);
                    input.setAttribute("name", "properties[" + option.field + "]");
                    //add placeholder
                    input.setAttribute("required", true);

                    //change event
                    input.addEventListener("change", function () {
                        self.previewPoster(false);
                    });

                    div.appendChild(label);
                    div.appendChild(input);

                    posterOptions.appendChild(div);
                }

                if (option.type == "location") {
                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--location-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--location-option");
                    label.setAttribute("for", "product-form__location-option-" + option.field);
                    label.innerHTML = option.label;

                    const input = document.createElement("input");
                    input.classList.add("product-form__input");
                    input.classList.add("product-form__input--location-option");
                    input.setAttribute("type", "text");
                    input.setAttribute("id", "product-form__location-option-" + option.field);
                    input.setAttribute("name", "properties[" + option.field + "]");
                    //add placeholder
                    input.setAttribute("required", true);

                    //init autocomplete
                    this.initAutocomplete(input);


                    div.appendChild(label);
                    div.appendChild(input);

                    posterOptions.appendChild(div);
                }


                if (option.type == "optionSelect") {

                    const div = document.createElement("div");
                    div.classList.add("product-form__item");
                    div.classList.add("product-form__item--custom-option");
                    div.classList.add("product-form__item--select-option");

                    const label = document.createElement("label");
                    label.classList.add("product-form__label");
                    label.classList.add("product-form__label--select-option");
                    label.setAttribute("for", "product-form__select-option-" + option.field);
                    label.innerHTML = option.label;

                    const select = document.createElement("select");
                    select.classList.add("product-form__input");
                    select.classList.add("product-form__input--select-option");
                    select.setAttribute("id", "product-form__select-option-" + option.field);
                    select.setAttribute("name", "properties[" + option.field + "]");
                    select.setAttribute("required", true);

                    select.addEventListener("change", function () {
                        self.previewPoster(false);
                    });

                    //add options
                    for (let o of option.targetId) {
                        const optionElement = document.createElement("option");
                        optionElement.setAttribute("value", o);
                        optionElement.innerHTML = o;
                        select.appendChild(optionElement);
                    }

                    div.appendChild(label);
                    div.appendChild(select);

                    posterOptions.appendChild(div);

                }

            }
        }
    }

    previewPoster(defaultImage = false) {
        if (!this.initConfigurator) return;
        //set state to prevent multiple calls
        if (this.loading) {
            this.tryToLoad++;
            return;
        }
        this.loading = true;

        if (!defaultImage) {
            //hide gallery
            try {
                document.getElementById("poster-image-gallery").style.display = "none";
            } catch {
                console.log("no gallery found");
            }
        }

        //show #poster-loading
        document.getElementById("poster-loading").style.display = "block";

        const previewImage = document.getElementById("poster-preview");

        //get options values
        let options = {};

        if (!defaultImage) {
            for (let option of this.poster.options) {
                if (option.type == "text") {
                    options[option.field] = document.getElementById("product-form__text-option-" + option.field).value;
                } else if (option.type == "select") {
                    options[option.field] = document.getElementById("product-form__select-option-" + option.field).value;
                } else if (option.type == "image") {
                    options[option.field] = option.src;
                } else if (option.type == "color") {
                    options[option.field] = document.getElementById("product-form__color-option-" + option.field).value;
                } else if (option.type == "location") {
                    options[option.field] = document.getElementById("product-form__location-option-" + option.field).value;
                } else if (option.type == "optionSelect") {
                    options[option.field] = document.getElementById("product-form__select-option-" + option.field).value;
                } else if (option.type == "mapbox") {
                    options[option.field] = document.getElementById("product-form__text-mapbox-" + option.field).value;
                } else if (option.type == "imageLink") {
                    options[option.field] = document.getElementById("product-form__text-imageLink-" + option.field).value;
                } else if (option.type == "fontSet") {
                    options[option.field] = document.getElementById("product-form__font-option-" + option.field).value;
                }

            }
        }

        //get preview image
        let url = this.api + "product/" + this.poster.SKU + "/preview";
        if (designId) {
            url += "?design=" + this.designId;
        }
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(options)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            previewImage.src = data.preview;

            this.designId = data.designId;
            //set designId to #product-form__design-id

            let designInput = document.getElementById("designId")
            if (designInput) {
                designInput.value = this.designId;
            }

            //hide #poster-loading
            document.getElementById("poster-loading").style.display = "none";

            this.loading = false;
            if (this.tryToLoad > 0) {
                this.tryToLoad = 0;
                this.previewPoster();
            }
        })
    }


    loadFont(font) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://svg.us.accelery.host/public/font/' + font + '/css';
        link.media = 'all';
        head.appendChild(link);
    }

    openCroppedDialog(imageSrc, option) {
        if (!this.initConfigurator) return;
        const croppedModal = document.getElementById("cropped-modal");
        croppedModal.style.display = "block";

        const croppedModalImage = document.getElementById("cropped-modal-image");
        croppedModalImage.src = imageSrc;

        var cropper = null;

        let self = this;
        croppedModalImage.onload = function () {
            cropper = self.initCroppedModal(croppedModalImage, option);
        }

        //add event listener to #cropped-modal-save button
        const croppedModalSave = document.getElementById("cropped-modal-save");
        croppedModalSave.addEventListener("click", function () {

            console.log(cropper);
            const croppedCanvas = cropper.getCroppedCanvas();

            console.log("croppedCanvas")
            self.scaleImageDataUrlDown(croppedCanvas.toDataURL(), option.width, function (scaledImage) {
                option.src = scaledImage;
                croppedModal.style.display = "none";
                this.previewPoster();
                croppedModalImage.src = "";
                cropper.destroy();

            });
        });

        //add event listener to #cropped-modal-close button
        const croppedModalClose = document.getElementById("cropped-modal-close");
        croppedModalClose.addEventListener("click", function () {
            croppedModalImage.src = "";
            croppedModal.style.display = "none";
            cropper.destroy();
        });

    }

    initCroppedModal(image, option) {
        if (!this.initConfigurator) return;
        const aspectRatio = option.aspectRatio;

        const cropper = new Cropper(image, {
            aspectRatio: aspectRatio,
            restore: false,
            guides: false,
            center: false,
            highlight: false,
            toggleDragModeOnDblclick: false,
            ready: function () {
                const croppedModalImageContainer = document.getElementById("cropped-modal-image-container");
                croppedModalImageContainer.style.height = croppedModalImageContainer.offsetWidth + "px";
            }
        });

        return cropper;
    }

    scaleImageDataUrlDown(dataUrl, maxWidth, callback) {
        if (!this.initConfigurator) return;

        const img = new Image();



        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        img.onload = function () {
            let width = img.width;
            let height = img.height;

            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);
            console.log("Image loadded", width, height);
            console.log(canvas.toDataURL("image/jpeg"));
            callback(canvas.toDataURL("image/jpeg"));
        }


        img.src = dataUrl;
    }

    initAutocomplete(adressInput) {
        if (!this.initConfigurator) return;

        var defaultBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(5.98865807458, 45.7769477403),
            new google.maps.LatLng(16.9796667823, 54.983104153)
        );

        let autocomplete = new google.maps.places.Autocomplete(adressInput, {
            fields: ["address_components", "geometry"],
            types: ["geocode"],
            bounds: defaultBounds
        });

        autocomplete.addListener("place_changed", () => this.fillInAddress(autocomplete, adressInput));
    }


    fillInAddress(autocomplete, input) {
        if (!this.initConfigurator) return;
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();

        let latlng = this.decimalToDMS(place.geometry.location.lat(), place.geometry.location.lng());


        if (input !== null && latlng) {
            input.value = latlng;

            this.previewPoster();
        }

    }

    decimalToDMS(latitude, longitude) {
        if (!this.initConfigurator) return;
        let latDegrees = Math.floor(latitude);
        let latMinutes = Math.floor((latitude - latDegrees) * 60);
        let latSeconds = Math.round((((latitude - latDegrees) * 60) - latMinutes) * 60 * 1000) / 1000;

        let lonDegrees = Math.floor(longitude);
        let lonMinutes = Math.floor((longitude - lonDegrees) * 60);
        let lonSeconds = Math.round((((longitude - lonDegrees) * 60) - lonMinutes) * 60 * 1000) / 1000;

        return `${latDegrees}° ${latMinutes}' ${latSeconds}" N, ${lonDegrees}° ${lonMinutes}' ${lonSeconds}" E`;
    }



}


export default AcceleryConfigurator;